import React, { useState } from 'react';
// import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import logo from './logo.svg';
import './App.css';
import { lightTheme, darkTheme, darkThemeNav, lightThemeNav } from './themes';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { About } from './About';
import { Contact } from './Contact';
// import { SelectTabData, SelectTabEvent, TabList, Tab, TabValue } from '@fluentui/react-components';
import {
  FluentProvider,
  makeStyles,
  shorthands,
  Tab,
  TabList,
  TabListProps,
  webDarkTheme,
  webLightTheme,
  PresenceBadgeStatus,
  Avatar,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Switch
} from "@fluentui/react-components";
import { Toggle, PartialTheme, CommandBar, Nav, INavLink, INavStyles, INavLinkGroup, ICommandBarItemProps, IButtonProps, setVirtualParent, FocusTrapZone, Checkbox, ThemeProvider, initializeIcons } from '@fluentui/react';
import {
  FolderRegular,
  EditRegular,
  OpenRegular,
  DocumentRegular,
  PeopleRegular,
  DocumentPdfRegular,
  VideoRegular,
} from "@fluentui/react-icons";
import type {
  OnSelectionChangeData,
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import { BrowserRouter, Routes, Route } from 'react-router-dom';


const navStyles: Partial<INavStyles> = {
  root: {
    width: 208,
    height: 350,
    // boxSizing: 'border-box',
    // border: '1px solid rgba(238,238,238,.5)',
    overflowY: 'auto'
  },
};

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("2px", "1px"),
    // rowGap: "1px",
  },
  toggle: {
    // ...shorthands.padding("2px", "1px"),
    float: "right"
  },
  panels: {
    ...shorthands.padding(0, "10px"),
    "& th": {
      textAlign: "left",
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

const wrapperStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const gridWrapperStyle: React.CSSProperties = {
  display: "flex",
  width: "100%"
};

const gridStyle: React.CSSProperties = {
  flex: "auto",
  height: "100%"
};

const projStyle: React.CSSProperties = {
  padding: '10px 40px'
};

const navStyle: React.CSSProperties = {
  width: 208
};

type FileCell = {
  label: string;
  icon: JSX.Element;
};

type LastUpdatedCell = {
  label: string;
  timestamp: number;
};

type LastUpdateCell = {
  label: string;
  icon: JSX.Element;
};

type AuthorCell = {
  label: string;
  status: PresenceBadgeStatus;
};

type Item = {
  file: FileCell;
  author: AuthorCell;
  lastUpdated: LastUpdatedCell;
  lastUpdate: LastUpdateCell;
};

// Initialize icons in case this example uses them
initializeIcons();

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

const CommandBarBasicExample: React.FunctionComponent = () => {

  return (
    <CommandBar
      items={_topNavItems}
      // overflowItems={_overflowItems}
      overflowButtonProps={overflowProps}
      farItems={_farItems}
      ariaLabel="Inbox actions"
      primaryGroupAriaLabel="Email actions"
      farItemsGroupAriaLabel="More actions"
    />
  );
};

const _topNavItems: ICommandBarItemProps[] = [
  {
    key: 'home',
    text: 'Cassie Quach',
    iconProps: { iconName: 'Home' },
    onClick: () => console.log('Home'),
  }
];

const _overflowItems: ICommandBarItemProps[] = [
  { key: 'move', text: 'Move to...', onClick: () => console.log('Move to'), iconProps: { iconName: 'MoveToFolder' } },
  { key: 'copy', text: 'Copy to...', onClick: () => console.log('Copy to'), iconProps: { iconName: 'Copy' } },
  { key: 'rename', text: 'Rename...', onClick: () => console.log('Rename'), iconProps: { iconName: 'Edit' } },
];

const _farItems: ICommandBarItemProps[] = [
  {
    key: 'tile',
    text: 'Grid view',
    // This needs an ariaLabel since it's icon-only
    ariaLabel: 'Grid view',
    iconOnly: true,
    iconProps: { iconName: 'Tiles' },
    onClick: () => console.log('Tiles'),
  },
  {
    key: 'info',
    text: 'Info',
    // This needs an ariaLabel since it's icon-only
    ariaLabel: 'Info',
    iconOnly: true,
    iconProps: { iconName: 'Info' },
    onClick: () => console.log('Info'),
  },
];

// export const TabExample = () => {
//   const [selectedValue, setSelectedValue] = React.useState<TabValue>('conditions');

//   const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
//     console.log(`The ${data.value} tab was selected`);
//     setSelectedValue(data.value);
//   };

//   return (
//     <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
//       <Tab value="tab1">First Tab</Tab>
//       <Tab value="tab2">
//         Second Tab
//       </Tab>
//       <Tab value="tab3">Third Tab</Tab>
//       <Tab value="tab4">Fourth Tab</Tab>
//     </TabList>
//   );
// };

// export const TabExample = () => {
//   const styles = useStyles();

//   const [selectedValue, setSelectedValue] =
//   React.useState<TabValue>("home");

//   const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
//     setSelectedValue(data.value);
//   };

//   return (
//     <div className={styles.root}>
//       <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
//         <Tab id="Home" value="home">Home</Tab>
//         <Tab id="About" value="about">About</Tab>
//         <Tab id="Contact" value="contact">Contact</Tab>
//       </TabList>
//       <div className={styles.panels}>
//         {/* {selectedValue === "home" && <Home />} */}
//         {/* {selectedValue === "about" && <About />} */}
//         {/* {selectedValue === "contact" && <Contact />} */}
//       </div>
//     </div>
//   );
// };

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: 'Folders',
        url: 'http://example.com',
        expandAriaLabel: 'Expand Home section',
        links: [
          {
            name: 'Inbox',
            url: 'http://msn.com',
            key: 'key1',
            target: '_blank',
            iconProps: { iconName: 'Inbox' },
          },
          {
            name: 'Drafts',
            url: 'http://msn.com',
            // disabled: true,
            key: 'key3',
            target: '_blank',
            iconProps: { iconName: 'Edit' },
          },
          {
            name: 'Sent Items',
            url: 'http://msn.com',
            // disabled: true,
            key: 'key4',
            target: '_blank',
            iconProps: { iconName: 'Send' },
          },
          {
            name: 'Archive',
            url: 'http://msn.com',
            // disabled: true,
            key: 'key5',
            target: '_blank',
            iconProps: { iconName: 'Archive' },
          },
          {
            name: 'Notes',
            url: 'http://msn.com',
            // disabled: true,
            key: 'key6',
            target: '_blank',
            iconProps: { iconName: 'QuickNote' },
          },
        ],
        isExpanded: true,
      },
      // {
      //   name: 'Documents',
      //   url: 'http://example.com',
      //   key: 'key3',
      //   isExpanded: true,
      //   target: '_blank',
      // },
      // {
      //   name: 'Pages',
      //   url: 'http://msn.com',
      //   key: 'key4',
      //   target: '_blank',
      // },
      // {
      //   name: 'Notebook',
      //   url: 'http://msn.com',
      //   key: 'key5',
      //   disabled: true,
      // },
      // {
      //   name: 'Communication and Media',
      //   url: 'http://msn.com',
      //   key: 'key6',
      //   target: '_blank',
      // },
      // {
      //   name: 'News',
      //   url: 'http://cnn.com',
      //   icon: 'News',
      //   key: 'key7',
      //   target: '_blank',
      // },
    ],
  },
];

export const NavBasicExample: React.FunctionComponent = () => {
  return (
    <Nav
      onLinkClick={_onLinkClick}
      selectedKey="key3"
      ariaLabel="Nav basic example"
      styles={navStyles}
      groups={navLinkGroups}
      // isOnTop={true}
    />
  );
};

function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
  if (item && item.name === 'News') {
    alert('News link clicked');
  }
}

const items: Item[] = [
  {
    file: { label: "Project 1", icon: <DocumentRegular /> },
    author: { label: "Cassie Quach", status: "available" },
    lastUpdated: { label: "7h ago", timestamp: 1 },
    lastUpdate: {
      label: "You edited this",
      icon: <EditRegular />,
    },
  },
  {
    file: { label: "Project 2", icon: <FolderRegular /> },
    author: { label: "Cassie Quach", status: "busy" },
    lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
    lastUpdate: {
      label: "You recently opened this",
      icon: <OpenRegular />,
    },
  },
  {
    file: { label: "Project 3", icon: <VideoRegular /> },
    author: { label: "Cassie Quach", status: "away" },
    lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
    lastUpdate: {
      label: "You recently opened this",
      icon: <OpenRegular />,
    },
  },
  {
    file: { label: "Project 4", icon: <DocumentPdfRegular /> },
    author: { label: "Cassie Quach", status: "offline" },
    lastUpdated: { label: "Tue at 9:30 AM", timestamp: 3 },
    lastUpdate: {
      label: "You shared this in a Teams chat",
      icon: <PeopleRegular />,
    },
  },
];

const columns: TableColumnDefinition<Item>[] = [
  createTableColumn<Item>({
    columnId: "file",
    compare: (a, b) => {
      return a.file.label.localeCompare(b.file.label);
    },
    renderHeaderCell: () => {
      return "File";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout media={item.file.icon}>
          <NavLink to={`/${item.file.label}`}>{item.file.label}</NavLink>
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<Item>({
    columnId: "author",
    compare: (a, b) => {
      return a.author.label.localeCompare(b.author.label);
    },
    renderHeaderCell: () => {
      return "Author";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout
          media={
            <Avatar
              aria-label={item.author.label}
              name={item.author.label}
              badge={{ status: item.author.status }}
            />
          }
        >
          {item.author.label}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<Item>({
    columnId: "lastUpdated",
    compare: (a, b) => {
      return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
    },
    renderHeaderCell: () => {
      return "Last updated";
    },

    renderCell: (item) => {
      return item.lastUpdated.label;
    },
  }),
  createTableColumn<Item>({
    columnId: "skillsUsed",
    compare: (a, b) => {
      return a.lastUpdate.label.localeCompare(b.lastUpdate.label);
    },
    renderHeaderCell: () => {
      return "Skills used";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout media={item.lastUpdate.icon}>
          {item.lastUpdate.label}
        </TableCellLayout>
      );
    },
  }),
];

interface IDarkModeProps {
  darkMode: boolean,
}

const Random1 = () => (
  <div style={projStyle}>
    <h1>Welcome to Project 1!</h1>
    <p> Feel free to browse around and learn more about me.</p>
  </div>
);

const Random2 = () => (
  <div style={projStyle}>
    <h1>Welcome to Project 2!</h1>
    <p> Feel free to browse around and learn more about me.</p>
  </div>
);
const Random3 = () => (
  <div style={projStyle}>
    <h1>Welcome to Project 3!</h1>
    <p> Feel free to browse around and learn more about me.</p>
  </div>
);
const Random4 = () => (
  <div style={projStyle}>
    <h1>Welcome to Project 4!</h1>
    <p> Feel free to browse around and learn more about me.</p>
  </div>
);
const Main = () => (
  <Routes>
    <Route path='/' element={<MainGrid />}></Route>
    <Route path='/home' element={<MainGrid />}></Route>
    <Route path='/project 1' element={<Random1 />}></Route>
    <Route path='/project 2' element={<Random2 />}></Route>
    <Route path='/project 3' element={<Random3 />}></Route>
    <Route path='/project 4' element={<Random4 />}></Route>
  </Routes>
);


export const MainGrid: React.FunctionComponent = () => {

  // let navigate = useNavigate(); 
  // const routeChange = () =>{ 
  //   let path = `project 1`; 
  //   navigate(path);
  // }

  // const handleSelectionChanged = (data: OnSelectionChangeData) => {
  //   switch(data.selectedItems.values().next().value) {
  //     case "Project 1": {
  //       console.log("Project 1 link");
  //       return routeChange;
  //       break;
  //     }
  //     case "Project 2": {
  //       console.log("Project 2 link");
  //       break;
  //     }
  //     case "Project 3": {
  //       console.log("Project 3 link");
  //       break;
  //     }
  //     case "Project 4": {
  //       console.log("Project 4 link");
  //       break;
  //     }
  //     default : {
  //       console.log("Default link");
  //     }
  //   }
  // };

  return (<div style={gridStyle}>
          <DataGrid
            items={items}
            columns={columns}
            sortable
            // selectionMode="single"
            getRowId={(item) => item.file.label}
            focusMode="composite"
            // onSelectionChange={(_, data: OnSelectionChangeData) => {
            //   console.log(data.selectedItems);
            //   handleSelectionChanged(data);
            // }}
            // onClick={(event) => {
            //     console.log(event.target);
            // }}
          >
            <DataGridHeader>
              <DataGridRow
                // selectionCell={{
                //   checkboxIndicator: { "aria-label": "Select all rows" },
                // }}
              >
                {({ renderHeaderCell }) => (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )}
              </DataGridRow>
            </DataGridHeader>
            <DataGridBody<Item>>
              {({ item, rowId }) => (
                
                  <DataGridRow<Item>
                    key={rowId}
                    // selectionCell={{
                    //   checkboxIndicator: { "aria-label": "Select row" },
                    // }}
                  >
                    {({ renderCell }) => (
                      <DataGridCell>{renderCell(item)}</DataGridCell>
                    )}
                  </DataGridRow>
                
              )}
            </DataGridBody>
          </DataGrid>
        </div>
  )
};

export const Home: React.FunctionComponent<IDarkModeProps> = (props: IDarkModeProps) => {
  const styles = useStyles();

  return (
    <FluentProvider theme={props.darkMode ? webDarkTheme : webLightTheme} >

      <div style={gridWrapperStyle}>
        {/* <div style={navStyle}>
          <ThemeProvider theme={props.darkMode ? darkTheme : lightTheme}>
            <NavBasicExample />
          </ThemeProvider>
        </div> */}
        <BrowserRouter>
          <Main />
        </BrowserRouter>
        
      </div>

    </FluentProvider>
  )
}