import { PartialTheme } from '@fluentui/react';

export const lightTheme: PartialTheme = {
    palette: {
     themePrimary: '#0078D4',
     themeLighterAlt: '#EFF6FC',
     themeLighter: '#DEECF9',
     themeLight: '#C7E0F4',
     themeTertiary: '#2B88D8',
     themeSecondary: '#0078D4',
     themeDarkAlt: '#106EBE',
     themeDark: '#005A9E',
     themeDarker: '#004578',
     neutralLighterAlt: '#faf9f8',
     neutralLighter: '#f3f2f1',
     neutralLight: '#edebe9',
     neutralQuaternaryAlt: '#e1dfdd',
     neutralQuaternary: '#d0d0d0',
     neutralTertiaryAlt: '#c8c6c4',
     neutralTertiary: '#a19f9d',
     neutralSecondary: '#605e5c',
     neutralPrimaryAlt: '#3b3a39',
     neutralPrimary: '#323130',
     neutralDark: '#201f1e',
     black: '#292929',
     white: '#ffffff',
   }
 };

 export const darkTheme: PartialTheme = {
   palette: {
     themePrimary: '#479ef5',
     themeDarkAlt: '#479ef5',
     neutralLighter: '#313131',
     neutralPrimary: '#ffffff',
     white: '#292929',
   }
 };

 export const lightThemeNav: PartialTheme = {
    palette: {
     themePrimary: '#f8f8f8',
     themeDarkAlt: '#f8f8f8',
     neutralLighter: '#2b5da1',
     neutralPrimary: '#f8f8f8',
     neutralDark: '#f8f8f8',
     white: '#316AB7',
   }
 };

 export const darkThemeNav: PartialTheme = {
  palette: {
   themePrimary: '#f8f8f8',
   themeDarkAlt: '#479ef5',
   neutralLighter: '#292929',
   neutralPrimary: '#f8f8f8',
   neutralDark: '#479ef5',
   white: '#333333',
 }
};