import React, { useState } from 'react';
// import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import logo from './logo.svg';
import './App.css';
import { lightTheme, darkTheme, darkThemeNav, lightThemeNav } from './themes';
import { useNavigate } from 'react-router-dom';
import { Home } from './Home';
import { About } from './About';
import { Contact } from './Contact';
// import { SelectTabData, SelectTabEvent, TabList, Tab, TabValue } from '@fluentui/react-components';
import {
  FluentProvider,
  makeStyles,
  shorthands,
  Tab,
  TabList,
  TabListProps,
  webDarkTheme,
  webLightTheme,
  PresenceBadgeStatus,
  Avatar,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Switch
} from "@fluentui/react-components";
import { Toggle, PartialTheme, CommandBar, Nav, INavLink, INavStyles, INavLinkGroup, ICommandBarItemProps, IButtonProps, setVirtualParent, FocusTrapZone, Checkbox, ThemeProvider, initializeIcons } from '@fluentui/react';
import {
  FolderRegular,
  EditRegular,
  OpenRegular,
  DocumentRegular,
  PeopleRegular,
  DocumentPdfRegular,
  VideoRegular,
} from "@fluentui/react-icons";
import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";

const navStyles: Partial<INavStyles> = {
  root: {
    width: 208,
    height: 350,
    // boxSizing: 'border-box',
    // border: '1px solid rgba(238,238,238,.5)',
    overflowY: 'auto'
  },
};

const useStyles = makeStyles({
  wrapper: {
    height: '100vh'
  },
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("2px", "1px"),
    // rowGap: "1px",
    height: "100%"
  },
  toggle: {
    // ...shorthands.padding("2px", "1px"),
    float: "right"
  },
  panels: {
    ...shorthands.padding("10px", "40px"),
    "& th": {
      textAlign: "left",
      ...shorthands.padding("10px", 0, 0, 0),
    },
    width: "100%",
  },
});

const wrapperStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const gridWrapperStyle: React.CSSProperties = {
  display: "flex",
  width: "100%"
};

const gridStyle: React.CSSProperties = {
  flex: "auto",
  height: "100%"
};

const navStyle: React.CSSProperties = {
  width: 208
};

type FileCell = {
  label: string;
  icon: JSX.Element;
};

type LastUpdatedCell = {
  label: string;
  timestamp: number;
};

type LastUpdateCell = {
  label: string;
  icon: JSX.Element;
};

type AuthorCell = {
  label: string;
  status: PresenceBadgeStatus;
};

type Item = {
  file: FileCell;
  author: AuthorCell;
  lastUpdated: LastUpdatedCell;
  lastUpdate: LastUpdateCell;
};

// Initialize icons in case this example uses them
initializeIcons();

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

const CommandBarBasicExample: React.FunctionComponent = () => {

  return (
    <CommandBar
      items={_topNavItems}
      // overflowItems={_overflowItems}
      overflowButtonProps={overflowProps}
      farItems={_farItems}
      ariaLabel="Inbox actions"
      primaryGroupAriaLabel="Email actions"
      farItemsGroupAriaLabel="More actions"
    />
  );
};

const _topNavItems: ICommandBarItemProps[] = [
  {
    key: 'home',
    text: 'Cassie Quach',
    iconProps: { iconName: 'Home' },
    href: "/"
  }
];

const _overflowItems: ICommandBarItemProps[] = [
  { key: 'move', text: 'Move to...', onClick: () => console.log('Move to'), iconProps: { iconName: 'MoveToFolder' } },
  { key: 'copy', text: 'Copy to...', onClick: () => console.log('Copy to'), iconProps: { iconName: 'Copy' } },
  { key: 'rename', text: 'Rename...', onClick: () => console.log('Rename'), iconProps: { iconName: 'Edit' } },
];

const _farItems: ICommandBarItemProps[] = [
  {
    key: 'tile',
    text: 'Grid view',
    // This needs an ariaLabel since it's icon-only
    ariaLabel: 'Grid view',
    iconOnly: true,
    iconProps: { iconName: 'Tiles' },
    onClick: () => console.log('Tiles'),
  },
  {
    key: 'info',
    text: 'Info',
    // This needs an ariaLabel since it's icon-only
    ariaLabel: 'Info',
    iconOnly: true,
    iconProps: { iconName: 'Info' },
    onClick: () => console.log('Info'),
  },
];

// export const TabExample = () => {
//   const [selectedValue, setSelectedValue] = React.useState<TabValue>('conditions');

//   const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
//     console.log(`The ${data.value} tab was selected`);
//     setSelectedValue(data.value);
//   };

//   return (
//     <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
//       <Tab value="tab1">First Tab</Tab>
//       <Tab value="tab2">
//         Second Tab
//       </Tab>
//       <Tab value="tab3">Third Tab</Tab>
//       <Tab value="tab4">Fourth Tab</Tab>
//     </TabList>
//   );
// };

// export const TabExample = () => {
//   const styles = useStyles();

//   const [selectedValue, setSelectedValue] =
//   React.useState<TabValue>("home");

//   const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
//     setSelectedValue(data.value);
//   };

//   return (
//     <div className={styles.root}>
//       <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
//         <Tab id="Home" value="home">Home</Tab>
//         <Tab id="About" value="about">About</Tab>
//         <Tab id="Contact" value="contact">Contact</Tab>
//       </TabList>
//       <div className={styles.panels}>
//         {/* {selectedValue === "home" && <Home />} */}
//         {/* {selectedValue === "about" && <About />} */}
//         {/* {selectedValue === "contact" && <Contact />} */}
//       </div>
//     </div>
//   );
// };

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: 'Folders',
        expandAriaLabel: 'Expand Home section',
        url: '',
        links: [
          {
            name: 'Inbox',
            url: '/',
            key: 'key1',
            iconProps: { iconName: 'Inbox' },
            // onClick(ev, item) {
              
            // },
          },
          {
            name: 'Drafts',
            url: '/about',
            // disabled: true,
            key: 'key3',
            iconProps: { iconName: 'Edit' },
          },
          {
            name: 'Sent Items',
            url: '/a',
            // disabled: true,
            key: 'key4',
            iconProps: { iconName: 'Send' },
          },
          {
            name: 'Archive',
            url: '/b',
            // disabled: true,
            key: 'key5',
            iconProps: { iconName: 'Archive' },
          },
          {
            name: 'Notes',
            url: '/c',
            // disabled: true,
            key: 'key6',
            iconProps: { iconName: 'QuickNote' },
          },
        ],
        isExpanded: true,
      },    
    ],
  },
];

export const NavBasicExample: React.FunctionComponent = () => {

  const [selectedValue, setSelectedValue] =
  React.useState<string>();
  
  const onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    setSelectedValue(item?.key);
  };

  return (
    <Nav
      onLinkClick={onLinkClick}
      selectedKey={selectedValue}
      ariaLabel="Nav basic example"
      styles={navStyles}
      groups={navLinkGroups}
      // isOnTop={true}
    />
  );
};
 

export const App: React.FunctionComponent = () => {
  const styles = useStyles();

  const [selectedValue, setSelectedValue] =
  React.useState<TabValue>("home");

  const [useDarkMode, setUseDarkMode] = useState(false);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  return (
    <FluentProvider theme={useDarkMode ? webDarkTheme : webLightTheme} className={styles.wrapper}>
      <ThemeProvider theme={useDarkMode ? darkThemeNav : lightThemeNav}>
        <CommandBarBasicExample />
      </ThemeProvider>
      <div className={styles.root}>
        <div style={wrapperStyle}>
          <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
            <Tab id="Home" value="home">Home</Tab>
            <Tab id="About" value="about">About</Tab>
            <Tab id="Contact" value="contact">Contact</Tab>
          </TabList>
          <Switch
              className={styles.toggle}
              label="Change themes"
              labelPosition="before"
              onChange={() => setUseDarkMode(!useDarkMode)}
            />
        </div>
        <div style={gridWrapperStyle}>
          <div style={navStyle}>
            <ThemeProvider theme={useDarkMode ? darkTheme : lightTheme}>
              <NavBasicExample />
            </ThemeProvider>
          </div>
          <div className={styles.panels}>
            {selectedValue === "home" && <Home darkMode={useDarkMode}/>}
            {selectedValue === "about" && <About darkMode={useDarkMode}/>}
            {selectedValue === "contact" && <Contact darkMode={useDarkMode}/>}
          </div>
        </div>
      </div>
    </FluentProvider>
  );
}