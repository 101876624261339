import React, { useState } from 'react';
// import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import logo from './logo.svg';
import './App.css';
import { lightTheme, darkTheme, darkThemeNav, lightThemeNav } from './themes';
import { NavLink } from 'react-router-dom';
// import { SelectTabData, SelectTabEvent, TabList, Tab, TabValue } from '@fluentui/react-components';
import {
  FluentProvider,
  makeStyles,
  shorthands,
  Tab,
  TabList,
  TabListProps,
  webDarkTheme,
  webLightTheme,
  PresenceBadgeStatus,
  Avatar,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  Persona,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Switch,
  Subtitle1
} from "@fluentui/react-components";
import { Toggle, PartialTheme, CommandBar, Nav, INavLink, INavStyles, INavLinkGroup, ICommandBarItemProps, IButtonProps, setVirtualParent, FocusTrapZone, Checkbox, ThemeProvider, initializeIcons } from '@fluentui/react';
import {
  FolderRegular,
  EditRegular,
  OpenRegular,
  DocumentRegular,
  PeopleRegular,
  DocumentPdfRegular,
  VideoRegular,
} from "@fluentui/react-icons";

const navStyles: Partial<INavStyles> = {
  root: {
    width: 208,
    height: 350,
    // boxSizing: 'border-box',
    // border: '1px solid rgba(238,238,238,.5)',
    overflowY: 'auto'
  },
};

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("2px", "1px"),
    // rowGap: "1px",
  },
  toggle: {
    // ...shorthands.padding("2px", "1px"),
    float: "right"
  },
  container: {
    ...shorthands.gap("16px"),
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
});

const wrapperStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const gridWrapperStyle: React.CSSProperties = {
  display: "flex",
  width: "100%"
};

const gridStyle: React.CSSProperties = {
  flex: "auto",
  height: "100%"
};

const navStyle: React.CSSProperties = {
  width: 208
};

type FileCell = {
  label: string;
  icon: JSX.Element;
};

type LastUpdatedCell = {
  label: string;
  timestamp: number;
};

type LastUpdateCell = {
  label: string;
  icon: JSX.Element;
};

type AuthorCell = {
  label: string;
  status: PresenceBadgeStatus;
};

type Item = {
  file: FileCell;
  author: AuthorCell;
  lastUpdated: LastUpdatedCell;
  lastUpdate: LastUpdateCell;
};

// Initialize icons in case this example uses them
initializeIcons();

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

const CommandBarBasicExample: React.FunctionComponent = () => {

  return (
    <CommandBar
      items={_topNavItems}
      // overflowItems={_overflowItems}
      overflowButtonProps={overflowProps}
      farItems={_farItems}
      ariaLabel="Inbox actions"
      primaryGroupAriaLabel="Email actions"
      farItemsGroupAriaLabel="More actions"
    />
  );
};

const _topNavItems: ICommandBarItemProps[] = [
  {
    key: 'home',
    text: 'Cassie Quach',
    iconProps: { iconName: 'Home' },
    onClick: () => console.log('Home'),
  }
];

const _overflowItems: ICommandBarItemProps[] = [
  { key: 'move', text: 'Move to...', onClick: () => console.log('Move to'), iconProps: { iconName: 'MoveToFolder' } },
  { key: 'copy', text: 'Copy to...', onClick: () => console.log('Copy to'), iconProps: { iconName: 'Copy' } },
  { key: 'rename', text: 'Rename...', onClick: () => console.log('Rename'), iconProps: { iconName: 'Edit' } },
];

const _farItems: ICommandBarItemProps[] = [
  {
    key: 'tile',
    text: 'Grid view',
    // This needs an ariaLabel since it's icon-only
    ariaLabel: 'Grid view',
    iconOnly: true,
    iconProps: { iconName: 'Tiles' },
    onClick: () => console.log('Tiles'),
  },
  {
    key: 'info',
    text: 'Info',
    // This needs an ariaLabel since it's icon-only
    ariaLabel: 'Info',
    iconOnly: true,
    iconProps: { iconName: 'Info' },
    onClick: () => console.log('Info'),
  },
];

// export const TabExample = () => {
//   const [selectedValue, setSelectedValue] = React.useState<TabValue>('conditions');

//   const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
//     console.log(`The ${data.value} tab was selected`);
//     setSelectedValue(data.value);
//   };

//   return (
//     <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
//       <Tab value="tab1">First Tab</Tab>
//       <Tab value="tab2">
//         Second Tab
//       </Tab>
//       <Tab value="tab3">Third Tab</Tab>
//       <Tab value="tab4">Fourth Tab</Tab>
//     </TabList>
//   );
// };

// export const TabExample = (props: Partial<TabListProps>) => {
//   const styles = useStyles();

//   return (
//     <div className={styles.root}>
//       <TabList {...props}>
//         <Tab value="tab1"><NavLink to='/'>Home</NavLink></Tab>
//         <Tab value="tab2"><NavLink to='/about'>About</NavLink></Tab>
//         <Tab value="tab3"><NavLink to='/contact'>Contact</NavLink></Tab>
//       </TabList>
//     </div>
//   );
// };

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: 'Folders',
        url: 'http://example.com',
        expandAriaLabel: 'Expand Home section',
        links: [
          {
            name: 'Inbox',
            url: 'http://msn.com',
            key: 'key1',
            target: '_blank',
            iconProps: { iconName: 'Inbox' },
          },
          {
            name: 'Drafts',
            url: 'http://msn.com',
            // disabled: true,
            key: 'key2',
            target: '_blank',
            iconProps: { iconName: 'Edit' },
          },
          {
            name: 'Sent Items',
            url: 'http://msn.com',
            // disabled: true,
            key: 'key2',
            target: '_blank',
            iconProps: { iconName: 'Send' },
          },
          {
            name: 'Archive',
            url: 'http://msn.com',
            // disabled: true,
            key: 'key2',
            target: '_blank',
            iconProps: { iconName: 'Archive' },
          },
          {
            name: 'Notes',
            url: 'http://msn.com',
            // disabled: true,
            key: 'key2',
            target: '_blank',
            iconProps: { iconName: 'QuickNote' },
          },
        ],
        isExpanded: true,
      },
      // {
      //   name: 'Documents',
      //   url: 'http://example.com',
      //   key: 'key3',
      //   isExpanded: true,
      //   target: '_blank',
      // },
      // {
      //   name: 'Pages',
      //   url: 'http://msn.com',
      //   key: 'key4',
      //   target: '_blank',
      // },
      // {
      //   name: 'Notebook',
      //   url: 'http://msn.com',
      //   key: 'key5',
      //   disabled: true,
      // },
      // {
      //   name: 'Communication and Media',
      //   url: 'http://msn.com',
      //   key: 'key6',
      //   target: '_blank',
      // },
      // {
      //   name: 'News',
      //   url: 'http://cnn.com',
      //   icon: 'News',
      //   key: 'key7',
      //   target: '_blank',
      // },
    ],
  },
];

export const NavBasicExample: React.FunctionComponent = () => {
  return (
    <Nav
      onLinkClick={_onLinkClick}
      selectedKey="key3"
      ariaLabel="Nav basic example"
      styles={navStyles}
      groups={navLinkGroups}
      // isOnTop={true}
    />
  );
};

function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
  if (item && item.name === 'News') {
    alert('News link clicked');
  }
}

const items: Item[] = [
  {
    file: { label: "Meeting notes", icon: <DocumentRegular /> },
    author: { label: "Max Mustermann", status: "available" },
    lastUpdated: { label: "7h ago", timestamp: 1 },
    lastUpdate: {
      label: "You edited this",
      icon: <EditRegular />,
    },
  },
  {
    file: { label: "Thursday presentation", icon: <FolderRegular /> },
    author: { label: "Erika Mustermann", status: "busy" },
    lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
    lastUpdate: {
      label: "You recently opened this",
      icon: <OpenRegular />,
    },
  },
  {
    file: { label: "Training recording", icon: <VideoRegular /> },
    author: { label: "John Doe", status: "away" },
    lastUpdated: { label: "Yesterday at 1:45 PM", timestamp: 2 },
    lastUpdate: {
      label: "You recently opened this",
      icon: <OpenRegular />,
    },
  },
  {
    file: { label: "Purchase order", icon: <DocumentPdfRegular /> },
    author: { label: "Jane Doe", status: "offline" },
    lastUpdated: { label: "Tue at 9:30 AM", timestamp: 3 },
    lastUpdate: {
      label: "You shared this in a Teams chat",
      icon: <PeopleRegular />,
    },
  },
];

const columns: TableColumnDefinition<Item>[] = [
  createTableColumn<Item>({
    columnId: "file",
    compare: (a, b) => {
      return a.file.label.localeCompare(b.file.label);
    },
    renderHeaderCell: () => {
      return "File";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout media={item.file.icon}>
          {item.file.label}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<Item>({
    columnId: "author",
    compare: (a, b) => {
      return a.author.label.localeCompare(b.author.label);
    },
    renderHeaderCell: () => {
      return "Author";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout
          media={
            <Avatar
              aria-label={item.author.label}
              name={item.author.label}
              badge={{ status: item.author.status }}
            />
          }
        >
          {item.author.label}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<Item>({
    columnId: "lastUpdated",
    compare: (a, b) => {
      return a.lastUpdated.timestamp - b.lastUpdated.timestamp;
    },
    renderHeaderCell: () => {
      return "Last updated";
    },

    renderCell: (item) => {
      return item.lastUpdated.label;
    },
  }),
  createTableColumn<Item>({
    columnId: "lastUpdate",
    compare: (a, b) => {
      return a.lastUpdate.label.localeCompare(b.lastUpdate.label);
    },
    renderHeaderCell: () => {
      return "Last update";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout media={item.lastUpdate.icon}>
          {item.lastUpdate.label}
        </TableCellLayout>
      );
    },
  }),
];

interface IDarkModeProps {
  darkMode: boolean,
}


export const About: React.FunctionComponent<IDarkModeProps> = (props: IDarkModeProps) => {
  const styles = useStyles();

  return (
    <FluentProvider theme={props.darkMode ? webDarkTheme : webLightTheme} >
      <div style={gridWrapperStyle}>
        {/* <div style={navStyle}>
          <ThemeProvider theme={props.darkMode ? darkTheme : lightTheme}>
            <NavBasicExample />
          </ThemeProvider>
        </div> */}

        <div className={styles.container}>
          <Persona
            textAlignment="start"
            name="Cassie Quach"
            presence={{ status: "available" }}
            avatar={{ color: "colorful" }}
            size="huge"
            secondaryText="Available"
            tertiaryText="Software Engineer 2 @ Microsoft turned Product Designer :)"
            quaternaryText=""
          />
          <Subtitle1>
            I've been a full stack software engineer for over 3 years and although it's been interesting, I've found 
            I enjoy the tangible, concrete, and feelable experience more. I made my way into being a UX Engineer for my team, but it didn't fully fulfill
            my itch to be a part of something more. Instead, I've found that in product/UX design and am excited for my next adventure in my next role.
          </Subtitle1>
        </div>
      </div>

    </FluentProvider>
  )
}